import axios from "axios";

const state = {
  countryList: [],
  projectTypes: [],
  marketingCampaignList: [],
  signupSourceList: [],
  signupCampaignList: [],
  signupMediumList: [],
};

const getters = {
  getCountryList(state) {
    return state.countryList;
  },
  getProjectTypes(state) {
    return state.projectTypes;
  },
  getMarketingCampaignList(state) {
    return state.marketingCampaignList;
  },
  getSignupSourceList(state) {
    return state.signupSourceList;
  },
  getSignupCampaignList(state) {
    return state.signupCampaignList;
  },
  getSignupMediumList(state) {
    return state.signupMediumList;
  },
};

const mutations = {
  setCountryList(state, payload) {
    state.countryList = payload;
  },
  setProjectTypes(state, payload) {
    state.projectTypes = payload;
  },
  setMarketingCampaignList(state, payload) {
    state.marketingCampaignList = payload;
  },
  setSignupSourceList(state, payload) {
    state.signupSourceList = payload;
  },
  setSignupCampaignList(state, payload) {
    state.signupCampaignList = payload;
  },
  setSignupMediumList(state, payload) {
    state.signupMediumList = payload;
  },
};

const actions = {
  getCountryList({ commit, getters }) {
    return new Promise((resolve) => {
      if (getters.getCountryList.length === 0) {
        axios.get("/staticContent/countries").then((res) => {
          commit("setCountryList", res.data.data);
          resolve("done");
        });
      } else {
        resolve("done");
      }
    });
  },
  getProjectTypes({ commit, getters }) {
    return new Promise((resolve) => {
      if (getters.getProjectTypes.length === 0) {
        axios.get("/staticContent/projectTypes").then((res) => {
          commit("setProjectTypes", res.data.data);
          resolve("done");
        });
      } else {
        resolve("done");
      }
    });
  },
  getMarketingCampaignList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/staticContent/marketingCampaigns`, {
          params: payload,
        })
        .then((response) => {
          const marketingCampaigns = response.data.campaigns;
          commit("setMarketingCampaignList", marketingCampaigns);
          resolve("done");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSignupSourceList({ commit }, payload) {
    return new Promise((resolve) => {
      axios
        .get(`/staticContent/signupSources`, {
          params: payload,
        })
        .then((res) => {
          commit("setSignupSourceList", res.data.data);
          resolve("done");
        });
    });
  },
  getSignupCampaignList({ commit }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/utmCampaigns`).then((res) => {
        commit("setSignupCampaignList", res.data.campaigns);
        resolve("done");
      });
    });
  },
  getSignupMediumList({ commit }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/utmMediums`).then((res) => {
        commit("setSignupMediumList", [...res.data.mediums]);
        resolve("done");
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
